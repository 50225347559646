import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import styled from 'styled-components'

const CategoryImage = styled.img`
  width: 400px;
  height: 250px;
  box-shadow: 0 0 3px grey;
`

const CategoryPage = data => {
  return(
    <Layout
      title = { 'Collection' }
      index
      description = { `A list of all Siu Sons' product category. Customers can start shopping online here.` }
    >
      <div className="container">
          <div className="text-center mt-5">
              <h1>Category</h1>
          </div>
          <div style = {{ height: '5vh' }}/>
          <CategoryWrapper data = { data } />
      </div>
    </Layout>
  )
}

export default CategoryPage



const CategoryWrapper = props => {

  const [ category, setCategory ] = useState( null )

  useEffect( () => {
    
    var arr = []
    
    props.data.data.allShopifyCollection.edges.map( node => {
      arr[ node.node.handle ] = node.node
    })
    
    setCategory( arr )

    return

  }, [] )

  if( ! category ) return <div/>

  return (
    <div 
      className="row product-main"
    >
      {
        category.balloon && <Category data = { category.balloon } />
      }
      {
        category[`candles-cake-pop-candy`] && <Category data = { category[`candles-cake-pop-candy`] } />
      }
      {
        category[`party-favors-pinata`] && <Category data = { category[`party-favors-pinata`] } />
      }
      {
        category[`party-accessories`] && <Category data = { category[`party-accessories`] } />
      }
      {
        category[`decoration`] && <Category data = { category[`decoration`] } />
      }
      {
        category[`gift-wrapping-bags`] && <Category data = { category[`gift-wrapping-bags`] } />
      }
      {
        category[`ensembles-tableware`] && <Category data = { category[`ensembles-tableware`] } />
      }
      {
        category[`consumables`] && <Category data = { category[`consumables`] } />
      }
      {
        category[`luau-princess`] && <Category data = { category[`luau-princess`] } />
      }
      {
        category[`wedding-baby`] && <Category data = { category[`wedding-baby`] } />
      }
      {
        category[`spring-summer`] && <Category data = { category[`spring-summer`] } />
      }
      {
        category[`seasonal`] && <Category data = { category[`seasonal`] } />
      }
      
    </div>
  )

}

const Category = props => {

  return (
    <div
      className = "Catalogue__item col-sm-12 col-md-6 col-by-4"
    >
      <Link 
        to = { `/collection/${ props.data.handle }` }
      >
        <div 
          className = "details_List"
          style = {{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          { 
            props.data.image ? (
              <CategoryImage
                src = { props.data.image.src } 
                alt = { props.data.title }
              />
            ) : (
              <div className="no-image">No Image</div>
            )
          }
          <div
            style = {{
              width: '100%',
              paddingTop: '25%',
              position: 'relative'
            }}
          >
            <p style = {{ color: 'grey', fontWeight: 'bold', textAlign: 'center', position: 'absolute', top:0, left:0,right:0, bottom:0 }}>{ props.data.title }</p>
          </div>
        </div>
      </Link>
    </div>
  )
}

export const query = graphql`
    query {
        allShopifyCollection {
          edges {
            node {
              handle
              title
              image {
                src
              }
            }
          }
        }
    }
`
